import React, { useEffect, useState } from "react";
import {
  ICompanyFormation,
  ICompanyFormationFullObject,
} from "../../../../../Interfaces/ICompanyFormation";
import {
  IShareHolderFullObject,
} from "../../../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../../../Interfaces/ICompanyFormationHasShareHolder";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { academicTitleArray } from "../../../../../StaticArraies/academicTitleArray";
import { genderArray } from "../../../../../StaticArraies/genderArray";
import { Search } from "@mui/icons-material";
import { ShareHolderEdit } from "./ShareHolder/ShareHolderEdit";
import { SharesSummaryTable } from "./Summary/SharesSummaryTable";
import { RepresentationSummary } from "./Summary/RepresentationSummary";
import { ClientInformation } from "../../../../generic/ClientInformation";
import {
  IContact,
  IContactFullObject,
} from "../../../../../Interfaces/IContact";
import { ICompany } from "../../../../../Interfaces/ICompany";
import { ILegalTransactionFullObject } from "../../../../../Interfaces/ILegalTransaction";
import { ICompanyFormationAnswer } from "../../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../../Interfaces/ICompanyFormationQuestion";
import { ICompanyFormationOption } from "../../../../../Interfaces/ICompanyFormationOption";
import { CompanyFormationAnswerSummary } from "./Summary/CompanyFormationAnswerSummary";
import { useTranslation } from "react-i18next";
import { IConfig } from "../../../../../Interfaces/IConfig";
import { getCompanyFormationAnswer } from "../companyFormation_helpers";
import { checkTranslation } from "../../../../core/standardFunctions";

export interface IProps {
  configObject: IConfig;
  handleBack: Function;
  handleNext: Function;
  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  companyFormationShareArray: ICompanyFormationShare[];
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];

  isTranslationEnabled?: boolean;
}

const checkString = (stringToCheck: string | undefined) => {
  if (stringToCheck == undefined || stringToCheck === "") {
    return "k.A.";
  } else {
    return stringToCheck;
  }
};

export const Summary: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);

  const [personObject, setPersonObject] = useState<IContact>({
    idContact: -1,
    idCompany: -1,
    idGender: 1,
    idAcademicTitle: 1,
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
  } as IContact);

  const [companyObject, setCompanyObject] = useState<ICompany>({
    idCompany: -1,
    Company: "",
    idBusinessForm: 1,
    idPostcode: null,
    Street: "",
    StreetNr: "",
    StreetAditional: "",
    PhoneNumber: "",
    Website: "",
    RegisterCourt: null,
    RegisterNumber: null,
    TaxNumber: null,
    SalesTaxNumber: null,
  });

  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);
  //
  const [companyQuestionObject, setCompanyQuestionObject] = useState(
    props.companyFormationQuestionArray.find(
      (x) => x.idCompanyFormationQuestion === 10
    )
  );
  const [companyAnswerObject, setCompanyAnswerObject] = useState(
    getCompanyFormationAnswer(10, props.companyFormationAnswerArray, 30)
  );

  useEffect(() => {
    const foundObject = props.companyFormationAnswerArray.find(
      (x) => x.idCompanyFormationQuestion === 10
    );

    if (foundObject === undefined) {
      props.setCompanyFormationAnswerArray([
        ...props.companyFormationAnswerArray,
        companyAnswerObject,
      ]);
    } else {
      props.setCompanyFormationAnswerArray([
        ...props.companyFormationAnswerArray.map((x) =>
          x.idCompanyFormationQuestion === 10 ? companyAnswerObject : x
        ),
      ]);
    }
  }, [companyAnswerObject]);

  async function handleSubmit() {
    setIsLoading(true);
    //handleNext();
    const legalTransactionFullObject = {
      idLegalTransaction: -1,
      idLegalTransactionState: 1,
      idLegalTransactionType: 1,
      idContact: -1,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
      CompanyFormation: {
        ...props.companyFormationObject,
        ShareHolderArray: props.shareHolderArray,
        CompanyFormationShareArray: props.companyFormationShareArray,
        CompanyFormationHasShareHolderArray: props.shareHolderRelation,
        CompanyFormationAnswerArray: props.companyFormationAnswerArray,
        DocumentArray: [],
      } as ICompanyFormationFullObject,
    } as ILegalTransactionFullObject;
    fetch("/api/legaltransaction/createCompanyFomration", {
      method: "POST",
      headers: {
        "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
        "Content-type": "application/json",
      },
      body: JSON.stringify(legalTransactionFullObject),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error(`ERROR GET: ${res.status}`);
        }
      })
      .then((res) => {
        setIsLoading(false);
        props.handleNext();
      })
      .catch((error) => {
        setWasSuccessfully(false);
      });
  }

  async function generatePDF() {
    const legalTransactionFullObject = {
      idLegalTransaction: -1,
      idLegalTransactionState: 1,
      idLegalTransactionType: 1,
      idContact: -1,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
      CompanyFormation: {
        ...props.companyFormationObject,
        ShareHolderArray: props.shareHolderArray,
        CompanyFormationShareArray: props.companyFormationShareArray,
        CompanyFormationHasShareHolderArray: props.shareHolderRelation,
        CompanyFormationAnswerArray: props.companyFormationAnswerArray,
        DocumentArray: [],
      } as ICompanyFormationFullObject,
    } as ILegalTransactionFullObject;
    fetch("/api/legaltransaction/createPdf", {
      method: "POST",
      headers: {
        "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
        "Content-type": "application/json",
      },
      body: JSON.stringify(legalTransactionFullObject),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error(`ERROR GET: ${res.status}`);
        }
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Dialog open={isLoading}>
        <DialogTitle>Bitte warten</DialogTitle>
        <DialogContent>
          Ihre Anfrage wird derzeitig hochgeladen.
          <Grid
            container
            sx={{ mt: 3 }}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              {wasSuccessfully ? (
                <CircularProgress />
              ) : (
                <Alert severity="error">Es ist ein Fehler aufgetretten!</Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {idShareHolder === null || idShareHolder === 0 ? (
            <>{t("new_person")}</>
          ) : (
            <>Person bearbeiten</>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {idShareHolder !== null && (
              <ShareHolderEdit
                idShareHolder={idShareHolder}
                handleClose={() => setIdShareHolder(null)}
                shareHolderArray={props.shareHolderArray}
                setShareHolderArray={props.setShareHolderArray}
                disableSelectCompanyType={
                  props.shareHolderArray.find(
                    (x) => x.idShareHolder === idShareHolder
                  )?.idBusinessForm === 1
                }
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Typography variant="h4" sx={{ mb: 3 }}>
        Abschluss
      </Typography>

      <Typography>
        Vielen Dank für die Eingabe Ihrer Daten. Bitte überprüfen Sie Ihre Daten
        nun noch einmal in der Übersicht. Wenn alles korrekt ist, senden Sie
        Ihre Daten bitte ab.
        <p />
        Wir werden Sie über den Fortschritt des Gründungsprozesses auf dem
        Laufenden halten!
      </Typography>

      <Box sx={{ mt: 5 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Abschließende Angaben zur Gründung
      </Typography>
      <TextField
        size="small"
        type="number"
        label="Gründung an die IHK übermitteln"
        fullWidth
        value={companyAnswerObject.idCompanyFormationOption}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCompanyAnswerObject({
            ...companyAnswerObject,
            idCompanyFormationOption: Number(event.target.value),
          })
        }
        select
      >
        {props.companyFormationOptionArray
          .filter((x) => x.idCompanyFormationQuestion === 10)
          .map((x) => (
            <MenuItem
              key={`idCompanyFormationQuestion-${x.idCompanyFormationOption}`}
              value={x.idCompanyFormationOption}
            >
              {x.CompanyFormationOption}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        sx={{ mt: 2 }}
        label="Anmerkungen"
        size="small"
        fullWidth
        value={
          props.companyFormationObject.Remarks === null
            ? ""
            : props.companyFormationObject.Remarks
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setCompanyFormationObject({
            ...props.companyFormationObject,
            Remarks: event.target.value === "" ? null : event.target.value,
          })
        }
        multiline
        rows={5}
      />

      <Box sx={{ mt: 5 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Zusammenfassung
      </Typography>
      <Grid container sx={{ pl: 10 }} spacing={2}>
        <Grid item sm={4}>
          <b>Name des Unternehmens:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.CompanyName)}
        </Grid>

        <Grid item sm={4}>
          <b>Adresse:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Street)}{" "}
          {checkString(props.companyFormationObject.StreetNr)}
        </Grid>

        <Grid item sm={4}></Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Postcode)}{" "}
          {checkString(props.companyFormationObject.City)}
        </Grid>

        <Grid item sm={4}>
          <b>Geschäftszweck:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.PurposeOfTheCompany)}
        </Grid>

        <Grid item sm={4}>
          <b>Beteiligte:</b>
        </Grid>
        <Grid item sm={8}>
          {props.shareHolderArray.length}
        </Grid>

        {(props.companyFormationObject.idBusinessForm === 8 ||
          props.companyFormationObject.idBusinessForm === 9) && (
          <>
            <Grid item sm={4}>
              <b>Stammkapital:</b>
            </Grid>
            <Grid item sm={8}>
              {props.companyFormationObject.ShareCapital.toLocaleString(
                "de-DE",
                {
                  style: "currency",
                  currency: "EUR",
                }
              )}
            </Grid>

            <Grid item sm={4}>
              <b>Anteile:</b>
            </Grid>
            <Grid item sm={8}>
              {props.companyFormationShareArray
                .map((x) => x.AmountOfShares)
                .reduce((a, b) => a + b, 0)}
            </Grid>

            {/*
                        <Grid item sm={4}><b>Preis pro Anteil:</b></Grid>
                        <Grid item sm={8}>{props.companyFormationObject.ValuePerShare.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                        })}</Grid>
                         */}
          </>
        )}
      </Grid>

      <CompanyFormationAnswerSummary
        companyFormationAnswerArray={props.companyFormationAnswerArray}
        companyFormationOptionArray={props.companyFormationOptionArray}
        companyFormationQuestionArray={props.companyFormationQuestionArray}
      />

      <Box sx={{ mt: 10 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Beteiligte
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{checkTranslation(t, "title", "Titel")}</TableCell>
            <TableCell>{checkTranslation(t, "Gender", "Geschlecht")}</TableCell>
            <TableCell>
              {checkTranslation(t, "first_name", "Vorname")}
            </TableCell>
            <TableCell>
              {checkTranslation(t, "last_name", "Nachname")}
            </TableCell>
            <TableCell>Rollen</TableCell>
            <TableCell>{checkTranslation(t, "actions", "Aktionen")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(Array.isArray(props.shareHolderArray)
            ? props.shareHolderArray
            : []
          ).map((x) => (
            <TableRow>
              {x.idBusinessForm === 1 ? (
                <>
                  <TableCell>
                    {x.idAcademicTitle === 1
                      ? ""
                      : checkString(
                          academicTitleArray.find(
                            (y) => y.idAcademicTitle === x.idAcademicTitle
                          )?.AcademicTitle
                        )}
                  </TableCell>
                  <TableCell>
                    {checkString(
                      genderArray.find((y) => y.idGender === x.idGender)?.Gender
                    )}
                  </TableCell>

                  <TableCell>{x.FirstName}</TableCell>
                  <TableCell>{x.LastName}</TableCell>
                </>
              ) : (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell colSpan={2}>{x.CompanyObject.Company}</TableCell>
                </>
              )}

              <TableCell>
                {props.shareHolderRelation
                  .filter((y) => y.idShareHolder === x.idShareHolder)
                  .map((x) =>
                    x.idShareHolderType === 1
                      ? "Gesellschafter"
                      : x.idShareHolderType === 2
                      ? "Geschäftsführer"
                      : x.idShareHolderType === 3
                      ? "Prokurist"
                      : x.idShareHolderType === 4
                      ? "GbR: Konkrete Vertretung"
                      : "Fehler"
                  )
                  .join(", ")}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => setIdShareHolder(x.idShareHolder)}>
                  <Search />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {props.companyFormationObject.idBusinessForm === 8 && (
        <>
          <SharesSummaryTable
            companyFormationObject={props.companyFormationObject}
            shareHolderArray={props.shareHolderArray}
            shareHolderRelation={props.shareHolderRelation}
            companyFormationShareArray={props.companyFormationShareArray}
          />

          <RepresentationSummary
            shareHolderArray={props.shareHolderArray}
            shareHolderRelation={props.shareHolderRelation}
          />
        </>
      )}

      <Box sx={{ mt: 10 }} />

      <ClientInformation
        configObject={props.configObject}
        disableHasAccount={
          props.configObject.Module.find((x) => x === "company_formation") ===
          undefined
        }
        contactFullObject={
          {
            ...personObject,
            CompanyObject: companyObject,
          } as IContactFullObject
        }
        setContactFullObject={(localFullObject: IContactFullObject) => {
          setPersonObject(localFullObject);
          setCompanyObject(localFullObject.CompanyObject);
        }}
        handleBack={() => props.handleBack()}
        handleSave={handleSubmit}
        isTranslationEnabled={false}
      />
    </>
  );
};
